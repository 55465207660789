<template>
  <div class="modal-container" v-if="showModal" @click="onClose">
    <div class="modal">
      <h2>Disclaimer</h2>
      <p class="body-content">
        I understand and agree that futures and options trading involve
        substantial risk due to leverage factor and may not be suitable for all
        investors. The content provided in this website are intended solely for
        information and education purposes. No liability is assumed for damages
        and/or losses that may result from the use of information contained
        within. Neither the information nor any opinion expressed constitutes an
        offer or an invitation to make an offer, to buy or sell any investments
        or any options, futures or derivatives related to such investments.
        Trading performance in Virtual Trading game does not guarantee of future
        results of the actual trading in real market.
      </p>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import Form from "@/components/Form";
import Message from "@/components/Message";

import { eventBus } from "@/main.js";

export default {
  components: {
    SubmitButton,
    Form,
    Message,
  },
  props: ["isShown"],
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    isShown(show) {
      this.showModal = show;
    },
  },
  methods: {
    actionBtn(state) {
      this.$emit("modalAction", state);
    },
    onClose(event) {
      if (event.target.classList.contains("modal-container")) {
        this.showModal = false;
        this.$emit("closeModal", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  background: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: grid;
  place-items: center;
  overflow: auto;

  .modal {
    background: #f0f0f0;
    box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.3);
    border-radius: 20px;
    max-width: 50ch;
    padding: 30px;
    margin: 30px;
    text-align: center;

    p {
      text-align: justify;
    }

    .img-wrapper {
      text-align: center;

      img {
        margin: 0 auto;
      }
    }

    #partitioned {
      padding-bottom: 5px;
      padding-left: 10px;
      font-size: 20px;
      letter-spacing: 39px;
      border: 0;
      background-color: transparent;
      background-image: linear-gradient(
        to left,
        #c41230 70%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 50px 2px;
      background-repeat: repeat-x;
      background-position-x: 35px;
      width: 260px;
      min-width: 260px;
    }

    .body-content {
      margin: 2rem 0;
    }

    .btn-wrapper {
      * + * {
        margin-top: 20px;
      }
    }
  }
}
</style>

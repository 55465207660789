<template>
   <div class="modal-container" v-if="showModal">
    <div class="modal">
       <div class="img-wrapper">
            <img src="@/assets/images/password_verification.svg" alt="">
       </div>
       <h3>Admin Access Verification</h3>
       <p>Enter the code that has been sent to {{this.email}}</p>
       <Form @submit="verifyAdmin">
				 <div id="divOuter">
					<div id="divInner">
						<input id="partitioned" type="numeric" v-model="otpCodeInput" maxlength="5" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==5) return false;"/>
					</div>
			 </div>
			 <Message :class="{error : this.error}">{{error}}</Message>
				<div class="resend-wrapper">
					 <p>Did not receive code?</p>
			 		<p :class="[{'inactive': countdownTimer > 0},'resend']" @click="resendToken">{{this.resendCodeString}}</p>
				</div>

			 <SubmitButton :spacerRight="true" class="button-submit">Verify & Proceed</SubmitButton>
			 </Form>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton';
import Form from '@/components/Form';
import Message from '@/components/Message';

import {eventBus} from "@/main.js";

import {checkLoginToken,login} from '@/services/api/users.js';

export default {
	components:{
		SubmitButton,Form,Message
	},
	props:['email','isShown','pw'],
	data(){
		return{
			otpCodeInput: '',
			showModal: false,
			error: '',
			countdownTimer: 60,
			resendCodeString: 'Resend code in 60s',
			timerInterval: null
		}
	},
	methods:{
		verifyAdmin(){
			this.error = '';
			eventBus.$emit('loader',true);
  			checkLoginToken({email: this.email, token: this.otpCodeInput}).then((response) => {
				const accessToken = response.data.response.token;
        		const userProfile = response.data.response.user;

				eventBus.$emit('loader',false);

				this.$store.commit('updateAuthenticated',{
					authenticated : true,
					userProfile : userProfile, 
					accessToken: accessToken
				})


			}).catch((error) => {
 				this.error = error.response.data.userMessage;
				eventBus.$emit('loader',false);
			})
			
		},
		async resendToken(){
			if(this.countdownTimer <= 0){
				try {
					eventBus.$emit('loader',true);
					const userData = {
						email: this.email,
						password: this.pw
					}
					await login(userData);
					eventBus.$emit('loader',false);

					this.$swal({
						icon: 'success',
						text: 'A verification code has been resent to your email.'
					}).then(() => {
						this.resendCodeString = 'Resend code in 60s';
						this.countdownTimer = 60;
						this.startCountDown();
					})



				} catch (error) {
					
				}
			}
		},
		startCountDown(){
			this.timerInterval = setInterval(() => {
				this.countdownTimer -= 1;
				this.resendCodeString = `Resend code in ${this.countdownTimer}s`
			}, 1000);
		}
	},
	watch:{
		isShown(show){
			this.showModal = show;
			this.startCountDown();
		},
		countdownTimer(value){
			if(value <= 0){
				clearInterval(this.timerInterval);
				this.timerInterval = null;
				this.resendCodeString = 'Resend code'
			}
		}
	}

}
</script>

<style lang="scss">
.modal-container{
    background: rgba($color: #000000, $alpha: 0.5); 
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
	display: grid;
	place-items: center;
	overflow: auto;

    .modal{
	  	
      background: #f0f0f0;
      box-shadow: 5px 5px 10px rgba($color: #000000, $alpha: 0.3);
      border-radius: 20px;
      padding: 30px;
	  margin: 30px;
      text-align: center;

      .img-wrapper{
          text-align: center;

          img{
              margin: 0 auto;
          }
      }

			#partitioned {
				padding-bottom: 5px;
				padding-left: 10px;
				font-size: 20px;
				letter-spacing: 39px;
				border: 0;
				background-color: transparent;
				background-image: linear-gradient(to left, #C41230 70%, rgba(255, 255, 255, 0) 0%);
				background-position: bottom;
				background-size: 50px 2px;
				background-repeat: repeat-x;
				background-position-x: 35px;
				width: 260px;
				min-width: 260px;
			}

			#divInner{
				left: 0;
				position: sticky;
			}

			#divOuter{
				margin: 0 auto;
				margin-top: 20px;
				width: 240px; 
				overflow: hidden;
			}

			h3{
				margin-top: 15px;
			}

			h3 + p{
				margin-top: 5px;
				font-size: 14px;
			}


			.resend-wrapper{
				font-size: 13px;
				margin-top: 40px;

				.resend{
					border-bottom: 2px solid $red;
					display: table;
					margin: 5px auto 0;
					color: $red;
					cursor: pointer;

					&.inactive{
						opacity: 0.3;
						cursor: not-allowed;
					}
				}
			}

			.button-submit{
				margin: 30px auto 0;
			}

    }
}
</style>
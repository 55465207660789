<template>
  <div>
    <Portal to="AdminVerification">
      <VerificationOverlay
        :email="userEmail"
        :isShown="isAdminAccess"
        :pw="confirmedPw"
      />
    </Portal>
    <PortalTarget name="AdminVerification" />

    <!-- Disclaimer Portal -->
    <Portal to="disclaimerPortal">
      <DisclaimerOverlay
        :isShown="openDisclaimer"
        @closeModal="modalActionResponse"
      />
    </Portal>
    <PortalTarget name="disclaimerPortal" />
    <Card title="Get Hands On With Futures Trading!">
      <Form
        title="Log in"
        :addSpacer="false"
        class="form"
        @submit="loginSubmit()"
      >
        <Input
          id="email"
          label="Your Email"
          type="email"
          placeholder="Email"
          hasIcon="true"
          iconType="email"
          class="bottom-spacing"
          @change="updateData($event, 'email')"
          rules="required|email"
        />

        <Input
          id="password"
          label="Your Password"
          type="password"
          placeholder="Password"
          hasIcon="true"
          iconType="password"
          autoComplete="new-password"
          class="last"
          @change="updateData($event, 'password')"
          rules="required"
        />

        <div class="flex-end">
          <router-link
            tag="a"
            :to="{ name: 'ResetPassword' }"
            class="forgot-password"
            >Forgot Password?</router-link
          >
        </div>
        <CheckBox
          id="disclaimer"
          @triggerModal="openDisclaimerModal"
          ref="checkbox"
          rules="required"
        >
          <template v-slot>I understand and agree to the </template>
          <template v-slot:button> disclaimer</template>
        </CheckBox>

        <Message :class="{ error: this.error }">{{ error }}</Message>

        <SubmitButton class="large-spacer">Sign In</SubmitButton>
      </Form>
      <span
        >Need an account?
        <router-link tag="a" :to="{ name: 'Register' }"
          >Sign Up</router-link
        ></span
      >
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Form from "@/components/Form";
import Input from "@/components/Input";
import CheckBox from "@/components/CheckBox";
import SubmitButton from "@/components/SubmitButton";
import Message from "@/components/Message";
import VerificationOverlay from "./components/VerificationOverlay";
import DisclaimerOverlay from "./components/DisclaimerOverlay";
import { Portal, PortalTarget } from "portal-vue";

//API
import { login } from "@/services/api/users.js";

import { eventBus } from "@/main.js";
import LoadingPlugin from "vue-loading-overlay";

export default {
  components: {
    Card,
    Form,
    Input,
    CheckBox,
    SubmitButton,
    Message,
    VerificationOverlay,
    DisclaimerOverlay,
    Portal,
    PortalTarget,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      isAdminAccess: false,
      userEmail: "",
      error: "",
      confirmedPw: "",
      openDisclaimer: false,
    };
  },
  methods: {
    updateData(data, attr) {
      this.user[attr] = data;
    },
    async loginSubmit() {
      this.error = "";
      try {
        eventBus.$emit("loader", true);
        const response = await login(this.user);
        const accessToken = response.data.response?.token;
        const userProfile = response.data.response.user;
        this.userEmail = response.data.response.user.email;
        this.confirmedPw = this.user.password;

        eventBus.$emit("loader", false);

        if (accessToken === null || accessToken == undefined) {
          this.isAdminAccess = true;
          return;
        }

        this.$store.commit("updateAuthenticated", {
          authenticated: true,
          userProfile: userProfile,
          accessToken: accessToken,
        });
      } catch (error) {
        this.error = error.response.data.userMessage;
        eventBus.$emit("loader", false);
      }
    },
    openDisclaimerModal() {
      this.openDisclaimer = true;
    },
    modalActionResponse(state) {
      this.openDisclaimer = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-top: 2em;
}
a {
  color: var(--redC41230);
}

button {
  margin: 3rem auto;
}

span {
  font-size: 15px;

  a {
    font-size: 16px;
    text-decoration: underline;

    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.forgot-password {
  font-size: 15px;
}
.checkbox-container {
  margin-top: 20px;
  margin-left: 0.9rem;
}

.underline {
  text-decoration: underline;
  color: $red;
  text-underline-offset: 5px;
  display: inline;
}
</style>
